<template>
    <div class="k-panel-1">
        <div class="k-flex-row k-flex-fill align-items-start">
            <div>
                <label> <b>Nom</b> <span class="text-danger">*</span></label>
                <b-form-input
                    v-model="localInstallation.name"
                    placeholder="Nom de l'installation"
                    :state="isNameValid"
                    @input="hasChanged = true"
                    trim
                />
                <b-form-invalid-feedback :state="isNameValid">
                    Veuillez saisir un nom
                </b-form-invalid-feedback>
            </div>
            <div>
                <label> <b>Type</b> <span class="text-danger">*</span></label>
                <b-form-select
                    v-model="localInstallation.type"
                    :options="installationTypeOptions"
                    :state="isTypeValid"
                    @input="hasChanged = true"
                >
                    <template #first>
                        <b-form-select-option :value="undefined" disabled>
                            -- Sélectionner le type de l'installation --
                        </b-form-select-option>
                    </template>
                </b-form-select>
                <b-form-invalid-feedback :state="isTypeValid">
                    Veuillez choisir un type
                </b-form-invalid-feedback>
            </div>
        </div>

        <div>
            <label>
                <b>Géolocalisation</b> <span class="text-danger">*</span>
            </label>
            <MapPointSelector
                :longitude="localInstallation.longitude"
                :latitude="localInstallation.latitude"
                :state="isGeoValid"
                @input="updateGeo($event)"
            />
        </div>

        <div>
            <label>Lieu de l'installation</label>
            <b-form-input
                v-model="localInstallation.place"
                placeholder="Adresse, emplacement, etc"
                @input="hasChanged = true"
                trim
            />
        </div>

        <div>
            <label class="m-0">Configuration TeamViewer</label>
            <div class="k-flex-row k-flex-fill">
                <div>
                    <small class="text-kalkin-1">Groupe</small>
                    <b-form-select
                        v-model="selectedTeamviewerGroup"
                        :options="teamviewerGroupsOptions"
                        @change="fetchTeamViewerDevices"
                    >
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                -- Sélectionner le groupe --
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </div>
                <div>
                    <small class="text-kalkin-1">Machine</small>
                    <div class="k-flex-row flex-nowrap">
                        <b-form-select
                            v-model="localInstallation.deviceId"
                            :options="teamviewerDevicesOptions"
                            @input="hasChanged = true"
                            :disabled="!selectedTeamviewerGroup"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>
                                    -- Sélectionner la machine --
                                </b-form-select-option>
                            </template>
                        </b-form-select>
                        <b-button
                            size="xs"
                            variant="outline-secondary"
                            @click="
                                localInstallation.deviceId = null;
                                hasChanged = true;
                            "
                        >
                            <b-icon-x-circle />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div>
                <label>Configuration avancée</label>
                <JSONTextArea
                    v-model="localInstallation.config"
                    @input="hasChanged = true"
                    @validate="isConfigValid = $event"
                />
            </div>
        </div>

        <div class="text-center">
            <b-button
                v-if="!isEditMode"
                variant="kalkin-2"
                @click="saveInstallation()"
            >
                <b-icon-plus-circle /> Créer l'installation
            </b-button>
            <b-button
                v-else
                variant="kalkin-2"
                :disabled="!hasChanged"
                @click="saveInstallation()"
            >
                <b-icon-check-circle-fill /> Enregistrer les modifications
            </b-button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import MapPointSelector from "@/components/map/MapPointSelector";
import JSONTextArea from "@/components/ui/item/JSONTextArea";
import configs from "@/config/installation";

const defaultInstallation = {
    name: undefined,
    type: undefined,
    place: undefined,
    longitude: undefined,
    latitude: undefined,
    config: undefined,
    deviceId: null,
};

export default {
    components: {
        MapPointSelector,
        JSONTextArea,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        installation: { type: Object, required: false },
    },

    data: function() {
        let localInstallation = Vue.util.extend(
            {},
            this.installation || defaultInstallation,
        );
        return {
            localInstallation,
            isEditMode: this.installation != null,
            installationTypeOptions: Object.keys(configs).map(t => {
                return { text: t, value: t };
            }),

            hasChanged: false,
            isConfigValid: true,

            teamviewerGroups: [],
            teamviewerDevices: [],

            selectedTeamviewerGroup: null,
        };
    },

    computed: {
        teamviewerGroupsOptions() {
            return this.teamviewerGroups.map(g => ({
                text: `${g.name} [${g.owner}]`,
                value: g.id,
            }));
        },

        teamviewerDevicesOptions() {
            return this.teamviewerDevices
                .map(g => ({
                    text: `${g.name} (${g.teamviewer})`,
                    value: g.id,
                }))
                .sort((a, b) => a.text.localeCompare(b.text));
        },

        isNameValid() {
            if (this.localInstallation.name === undefined) {
                return null;
            } else {
                return !!this.localInstallation.name;
            }
        },

        isTypeValid() {
            if (this.localInstallation.type === undefined) {
                return null;
            } else {
                return this.localInstallation.type !== null;
            }
        },

        isGeoValid() {
            if (
                this.localInstallation.longitude === undefined ||
                this.localInstallation.latitude === undefined
            ) {
                return null;
            } else {
                return (
                    !!this.localInstallation.longitude ||
                    !!this.localInstallation.latitude
                );
            }
        },

        isValid() {
            return (
                this.isNameValid &&
                this.isTypeValid &&
                this.isGeoValid &&
                this.isConfigValid
            );
        },
    },

    methods: {
        async fetchTeamViewerGroups() {
            this.teamviewerGroups = await this.$store.dispatch(
                "admin/getTeamViewerGroups",
            );
        },

        async fetchTeamViewerDevices() {
            if (this.selectedTeamviewerGroup) {
                this.localInstallation.deviceId = null;
                this.teamviewerDevices = await this.$store.dispatch(
                    "admin/getTeamViewerDevices",
                    {
                        groupid: this.selectedTeamviewerGroup,
                    },
                );
            }
        },

        async fetchTeamViewerDevice(deviceId) {
            return await this.$store.dispatch("admin/getTeamViewerDevice", {
                deviceId,
            });
        },

        updateGeo({ longitude, latitude }) {
            this.localInstallation.longitude = longitude;
            this.localInstallation.latitude = latitude;
            this.hasChanged = true;
        },

        async saveInstallation() {
            if (this.isValid) {
                if (!this.isEditMode) {
                    await this.$store.dispatch("project/createInstallation", {
                        projectId: this.projectId,
                        ...this.localInstallation,
                    });
                } else {
                    await this.$store.dispatch("project/updateInstallation", {
                        projectId: this.projectId,
                        installationId: this.installation.id,
                        ...this.localInstallation,
                    });
                }
                this.$emit("saved");
                this.hasChanged = false;
            } else {
                this.localInstallation.name = this.localInstallation.name || "";
                this.localInstallation.type =
                    this.localInstallation.type || null;
                this.localInstallation.longitude =
                    this.localInstallation.longitude || "";
                this.localInstallation.latitude =
                    this.localInstallation.latitude || "";
            }
        },
    },

    async beforeMount() {
        await this.fetchTeamViewerGroups();

        if (this.localInstallation && this.localInstallation.deviceId) {
            const device = await this.fetchTeamViewerDevice(
                this.localInstallation.deviceId,
            );
            if (device) {
                this.selectedTeamviewerGroup = device.groupid;
                await this.fetchTeamViewerDevices();
                this.localInstallation.deviceId = device.id;
            }
        }
    },
};
</script>
