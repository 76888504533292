<template>
    <div>
        <GenericForm
            class="generic-form-flex"
            :schema="schema"
            :model="localSpecs"
            @validated="isValid = $event"
            @updated="onUpdate"
        />
        <b-button
            class="mx-auto d-block"
            size="md"
            variant="kalkin-1"
            :disabled="!hasChanged"
            @click="onSave"
        >
            Enregistrer les modifications
        </b-button>
    </div>
</template>

<script>
import GenericForm from "@/components/form-generator/GenericForm";

import Formatter from "@/tools/format";
import configs from "@/config/installation";

export default {
    components: {
        GenericForm,
    },

    props: {
        projectId: { type: [String, Number], required: true },
        installation: { type: Object, required: true },
    },

    data: function() {
        const config = configs[this.installation.type];
        let schema = {};
        if (config) {
            schema = Formatter.convertCOREtoFORM(config.form);
        }
        return {
            schema,
            localSpecs: this.installation.specs || {},
            isValid: true,
            hasChanged: false,
        };
    },

    methods: {
        onUpdate(data) {
            this.localSpecs = data;
            this.hasChanged = true;
        },

        async onSave() {
            if (this.isValid) {
                await this.$store.dispatch("project/updateInstallation", {
                    projectId: this.projectId,
                    installationId: this.installation.id,
                    specs: this.localSpecs,
                });

                this.$emit("saved");
                this.hasChanged = false;
            }
        },
    },
};
</script>
