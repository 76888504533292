<template>
    <b-modal
        id="ModalUpdateInstallation"
        title="Modifier une installation"
        size="xl"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormInstallation
            :project-id="projectId"
            :installation="installation"
            @saved="onSaved"
            class="mb-2"
        />
    </b-modal>
</template>
<script>
import FormInstallation from "@/components/model/installation/FormInstallation";
export default {
    components: {
        FormInstallation,
    },

    props: {
        installation: { type: Object, required: true },
        projectId: { type: [String, Number], required: true },
    },

    methods: {
        onSaved() {
            this.$bvModal.hide("ModalUpdateInstallation");
            this.$emit("saved");
        },
    },
};
</script>
