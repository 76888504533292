<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Installations du projet</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                v-b-modal.ModalCreateInstallation
            >
                <b-icon-plus-circle /> Nouvelle installation
            </b-button>
        </div>
        <b-overlay
            class="k-page-body"
            :show="isLoading"
            spinner-variant="kalkin-2"
        >
            <GridCardBasic :data="installations" empty="Aucune installation">
                <template v-slot="{ data }">
                    <CardInstallationAdmin
                        :installation="data"
                        :project-id="projectId"
                        @specs="onInstallationSpecs(data)"
                        @update="onInstallationUpdate(data)"
                        @delete="onInstallationDelete(data)"
                    />
                </template>
            </GridCardBasic>
        </b-overlay>

        <ModalCreateInstallation
            :project-id="projectId"
            @saved="onInstallationCreated"
        />
        <ModalInstallationSpecs
            :project-id="projectId"
            :installation="selectedInstallation"
            @saved="onInstallationUpdated"
        />
        <ModalUpdateInstallation
            :project-id="projectId"
            :installation="selectedInstallation"
            @saved="onInstallationUpdated"
        />
        <ModalDanger
            @confirmed="onDeleteConfirmed"
            message="Voulez vous vraiment supprimer cette installation?"
        />
    </div>
</template>

<script>
import GridCardBasic from "@/components/ui/GridCardBasic";
import CardInstallationAdmin from "@/components/model/installation/CardInstallationAdmin";
import ModalCreateInstallation from "@/components/model/installation/ModalCreateInstallation";
import ModalUpdateInstallation from "@/components/model/installation/ModalUpdateInstallation";
import ModalInstallationSpecs from "@/components/model/installation/ModalInstallationSpecs";
import ModalDanger from "@/components/modal/ModalDanger";

export default {
    components: {
        GridCardBasic,
        CardInstallationAdmin,
        ModalCreateInstallation,
        ModalUpdateInstallation,
        ModalInstallationSpecs,
        ModalDanger,
    },

    data: function() {
        return {
            projectId: this.$route.params.projectId,
            installations: [],
            selectedInstallation: {},

            isLoading: false,
        };
    },

    methods: {
        async fetchInstallations() {
            this.isLoading = true;
            this.installations = await this.$store.dispatch(
                "project/getInstallations",
                {
                    projectId: this.projectId,
                },
            );
            this.isLoading = false;
        },

        onInstallationSpecs(installation) {
            this.selectedInstallation = installation;
            this.$bvModal.show("ModalInstallationSpecs");
        },

        onInstallationUpdate(installation) {
            this.selectedInstallation = installation;
            this.$bvModal.show("ModalUpdateInstallation");
        },

        onInstallationDelete(installation) {
            this.selectedInstallation = installation;
            this.$bvModal.show("ModalDanger");
        },

        async onInstallationCreated() {
            await this.fetchInstallations();
        },

        async onInstallationUpdated() {
            this.selectedInstallation = {};
            await this.fetchInstallations();
        },

        async onDeleteConfirmed() {
            await this.$store.dispatch("project/deleteInstallation", {
                projectId: this.projectId,
                installationId: this.selectedInstallation.id,
            });
            this.onInstallationUpdated();
        },
    },

    async beforeMount() {
        await this.fetchInstallations();
    },
};
</script>
