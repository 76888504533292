<template>
    <div class="card k-card-2">
        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Nom</small>
                <div>
                    <b>{{ installation.name }}</b>
                </div>
            </div>
            <div>
                <b-button
                    class="m-1"
                    size="xs"
                    variant="outline-secondary"
                    @click="$emit('update')"
                >
                    <b-icon-pencil />
                </b-button>
                <b-button
                    class="m-1"
                    size="xs"
                    variant="outline-danger"
                    @click="$emit('delete')"
                >
                    <b-icon-trash />
                </b-button>
            </div>
        </div>
        <div class="k-flex-row k-flex-fill mt-2">
            <div>
                <small class="text-kalkin-1">Type</small>
                <div>
                    {{ installation.type }}
                </div>
            </div>
            <div>
                <small class="text-kalkin-1"> Clé <b-icon-key /></small>
                <div>
                    <span class="key">
                        {{ `${installation.id}/${installation.key}` }}
                    </span>
                </div>
            </div>
        </div>
        <div>
            <small class="text-kalkin-1">Lieu</small>
            <div>{{ installation.place || "Non défini" }}</div>
        </div>

        <div class="k-flex-row">
            <div>
                <small class="text-kalkin-1">Longitude</small>
                <div>
                    {{ installation.longitude }}
                </div>
            </div>
            <div>
                <small class="text-kalkin-1">Latitude</small>
                <div>
                    {{ installation.latitude }}
                </div>
            </div>
            <b-button
                size="xs"
                variant="outline-kalkin-1"
                target="_blank"
                rel="noopener noreferrer"
                :href="
                    `https://www.google.fr/maps/place/${installation.latitude},${installation.longitude}`
                "
            >
                <b-icon-google /> Maps
            </b-button>
        </div>

        <div>
            <small class="text-kalkin-1">
                Dernier contact avec l'API Kalkin
            </small>
            <div v-if="installation.contactedAt">
                <b-icon-thermometer-half
                    :variant="computeThermo(installation.contactedAt)"
                    scale="1.5"
                />
                {{ installation.contactedAt | daysSinceISO }}
            </div>
            <div v-else>
                <b-icon-thermometer variant="danger" scale="1.5" />
                Jamais
            </div>
        </div>

        <div>
            <small class="text-kalkin-1">Date de création</small>
            <div>Le {{ installation.createdAt | dateFromISO }}</div>
        </div>

        <b-button size="xs" variant="kalkin-1" @click="$emit('specs')">
            <b-icon-gear-fill /> Spécifications techniques
        </b-button>

        <div class="border rounded p-2">
            <div class="k-flex-row mb-2">
                <img
                    style="height:20px"
                    src="@/assets/img/teamviewer-logo.svg"
                />
            </div>

            <div v-if="teamviewer && !teamviewer.error">
                <small class="text-primary">Machine </small>
                <div class="k-flex-row-2">
                    {{ teamviewer.name }}
                    <small class="border rounded text-secondary px-1">
                        {{ teamviewer.id }}
                    </small>
                </div>

                <small class="text-primary">Etat </small>
                <div class="k-flex-row-2">
                    <template v-if="teamviewer.online">
                        <b-icon-record-circle-fill
                            font-scale="1.2"
                            variant="kalkin-2"
                        />
                        <small>Connecté</small>
                    </template>
                    <template v-else>
                        <b-icon-slash-circle
                            font-scale="1.2"
                            variant="danger"
                        />
                        <small>
                            Déconnecté depuis
                            <template v-if="teamviewer.lastSeen">
                                le {{ teamviewer.lastSeen | dateFromISO }}
                            </template>
                            <template v-else> une date inconnue</template>
                        </small>
                    </template>
                </div>
            </div>
            <template v-else-if="teamviewer && teamviewer.error">
                <span class="kt-error">{{ teamviewer.error }}</span>
            </template>
            <div class="text-center text-secondary" v-else>
                <small>Non configuré</small>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        installation: Object,
    },

    computed: {
        teamviewer() {
            return this.installation.teamviewer;
        },
    },

    methods: {
        computeThermo(contactedAt) {
            const d = new Date(contactedAt).setUTCHours(0, 0, 0, 0);
            const today = new Date().setUTCHours(0, 0, 0, 0);
            const diffDays = Math.round(Math.abs((today - d) / 86400000));

            switch (diffDays) {
                case 0:
                    return "kalkin-2";
                case 1:
                    return "warning";
                default:
                    return "danger";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.key {
    padding: 4px 6px;
    border: 1px solid #ddd;
    border-radius: 6px;
    white-space: nowrap;
    font-family: monospace;
    font-size: 12px;
    background-color: #fff;
    user-select: all;
}
</style>
